import { MIN_QTY, MAX_QTY } from '@/settings/Cart';
import { NOT_FOUND, FORBIDDEN, BAD_REQUEST } from '@/settings/Errors';
import Packages from '@/util/GeneralInformation';
import Cart from '@/util/Cart';
import GiftOrderCart from '@/util/GiftOrderCart';
import RelatedProducts from '@/util/Products';
import { DDDDMMMDO_FORMAT } from '@/settings/Dates';
import AgencySite from '@/mixins/AgencySite';

export default {
	mixins: [AgencySite],
	data() {
		return {
			alert: new this.$Alert(),
			cart: new Cart(),
			createGiftOrderCart: new GiftOrderCart(),
			removeCart: new Cart(),
			addCartProduct: new Cart(),
			usePointsUtil: new Cart(),
			removePointsUtil: new Cart(),
			splitPaymentUtil: new Cart(),
			upgradePackagesUtil: new Packages(),
			cartRelated: new RelatedProducts(),
			qty: {},
			cartOptions: {},
			eventAttendees: {},
			ordinalDateFormat: DDDDMMMDO_FORMAT,
		};
	},
	computed: {
		possibleDiscountMessages() {
			try {
				return this.cart.data.response.data.data.attributes.possible_discounts;
			} catch (error) {
				return [];
			}
		},
		promotions() {
			try {
				return this.cart.data.response.data.data.attributes.promotions;
			} catch (error) {
				return [];
			}
		},
		showMessages() {
			return this.possibleDiscountMessages.length > 0 || this.promotions.length > 0 || this.freeProductsInfo.length > 0;
		},
		cartErrors() {
			return this.cart.data.errors;
		},
		removeCartErrors() {
			return this.removeCart.data.errors;
		},
		addCartProductErrors() {
			return this.addCartProduct.data.errors;
		},
		existingCartErrors() {
			return {
				cartErrors: this.cartErrors,
				removeCartErrors: this.removeCartErrors,
				addCartProductErrors: this.addCartProductErrors,
			};
		},
		cartPagination() {
			return this.cart.data.pagination;
		},
		cartProducts() {
			try {
				return this.cartData.attributes.products;
			} catch (error) {
				return [];
			}
		},
		isVirtualOrder() {
			try {
				return this.cartData.attributes.is_virtual;
			} catch (error) {
				return false;
			}
		},
		hideAddresses() {
			try {
				return this.cartData.attributes.hide_address;
			} catch (error) {
				return false;
			}
		},
		hidePayment() {
			try {
				return this.cartData.attributes.hide_payment;
			} catch (error) {
				return false;
			}
		},
		skipPaymentMinValidation() {
			try {
				return this.cartData.attributes.remove_payment_min_limit;
			} catch (error) {
				return false;
			}
		},
		cartTotals() {
			try {
				return this.cartData.attributes.totals;
			} catch (error) {
				return {};
			}
		},
		cartSmallTextTotals() {
			try {
				return this.cartData.attributes.small_subtotals;
			} catch (error) {
				return [];
			}
		},
		freeShippingInfo() {
			try {
				return this.cartData.attributes.free_shipping_info;
			} catch (error) {
				return null;
			}
		},
		cartNumericTotals() {
			try {
				return this.cartData.attributes.numeric_totals;
			} catch (error) {
				return {};
			}
		},
		cartNumericTotal() {
			try {
				return this.cartData.attributes.numeric_total;
			} catch (error) {
				return 0;
			}
		},
		numericTotalPurchased() {
			try {
				return Math.ceil((this.cartNumericTotals.subtotal || 0) + (this.cartNumericTotals.discount || 0));
			} catch (error) {
				return 0;
			}
		},
		totalVolume() {
			try {
				return this.cartData.attributes.total_volume.toString();
			} catch (error) {
				return '';
			}
		},
		upgradeVolume() {
			try {
				return this.cartData.attributes.upgrade_volume;
			} catch (error) {
				return null;
			}
		},
		cartTotal() {
			try {
				return this.cartData.attributes.total;
			} catch (error) {
				return '';
			}
		},
		shippingMethod() {
			try {
				return this.cartData.attributes.shipping_method;
			} catch (error) {
				return '';
			}
		},
		applyFreeBottle() {
			try {
				return this.country === 'US' && this.cartData.attributes.apply_free_bottle;
			} catch (error) {
				return false;
			}
		},
		freeBottleMessage() {
			try {
				return this.cartData.attributes.free_bottle_message;
			} catch (error) {
				return '';
			}
		},
		termsAndPolicies() {
			try {
				return this.cartMeta.terms;
			} catch (error) {
				return {};
			}
		},
		cartData() {
			try {
				return this.cart.data.response.data.data;
			} catch (error) {
				return [];
			}
		},
		cartMeta() {
			try {
				return this.cart.data.response.data.meta;
			} catch (error) {
				return [];
			}
		},
		cartHasData() {
			const response = Object.keys(this.cartData).length;
			return !!response;
		},
		exchange() {
			try {
				const { exchange } = this.cart.data.response.data.data.attributes;
				return exchange;
			} catch (error) {
				return [];
			}
		},
		productsHasData() {
			if (this.cartHasData) {
				const response = this.cartData.attributes.products.length;
				return !!response;
			}
			return false;
		},
		totalsHasData() {
			if (this.cartHasData) {
				const response = Object.keys(this.cartData.attributes.totals).length;
				return !!response;
			}
			return false;
		},
		cartRemovalResponse() {
			try {
				const { response } = this.removeCart.data.response.data;
				return response;
			} catch (error) {
				return [];
			}
		},
		upgradePackages() {
			try {
				return this.upgradePackagesUtil.data.response.data.data.map((item, index) => ({ id: item.id, index, ...item.attributes }));
			} catch (error) {
				return [];
			}
		},
		loadingUpgradePackages() {
			try {
				return this.upgradePackagesUtil.data.loading;
			} catch (error) {
				return true;
			}
		},
		hasUpgradePackages() {
			return !!this.upgradePackages.length;
		},
		systemLoading() {
			const ops = [
				this.cart,
				this.addCartProduct,
				this.removeCart,
				this.createCart,
				this.createGiftOrderCart,
				this.usePointsUtil,
				this.removePointsUtil,
				this.upgradePackagesUtil,
			];

			return ops.reduce((loading, op) => (!!op.data?.loading || loading), false);
		},
		splitPaymentInfo() {
			try {
				return this.cartData.attributes.split_payments;
			} catch (error) {
				return {};
			}
		},
		freeProductsInfo() {
			try {
				return this.cartData.attributes.free_products_info;
			} catch (error) {
				return [];
			}
		},
		allowGiftCart() {
			try {
				return this.cartData.attributes.available_for_gift;
			} catch (error) {
				return false;
			}
		},
	},
	watch: {
		existingCartErrors: {
			deep: true,
			handler(errors) {
				Object.keys(errors).forEach((key) => {
					if ([...NOT_FOUND, ...FORBIDDEN, ...BAD_REQUEST].includes(errors[key].status)) {
						this.createNewGiftOrderCart().then(() => {
							const redirectName = this.getRedirectName('Store');
							if (this.$route.name !== redirectName) {
								this.$router.replace({ name: redirectName });
							}
						});
					}
				});
			},
		},
		cartId() {
			if ((this.showPublicStore) || this.$route.name === 'RegisterConfirmation') {
				this.getCartRelated();
				this.getCartTotals();
			}
		},
	},
	methods: {
		async updateQuantity(newQuantity, product, bypassMinQty = false) {
			try {
				let quantity = bypassMinQty ? 0 : MIN_QTY;

				if (Number.isNaN(newQuantity) || newQuantity > MIN_QTY) quantity = newQuantity;
				if (Number.isNaN(newQuantity) || newQuantity > MAX_QTY) {
					quantity = MAX_QTY;
					this.alert.toast('info', this.translate('max_qty', { max: MAX_QTY }));
				}
				const products = { [product.sku]: quantity };

				await this.addCartProduct.addCartProducts(this.cartId, { products, replace_qty: true, sponsor_id: this.$replicated.replicatedSponsor() });
				this.qty[product.item_id] = quantity;
				this.getCartTotals();
			} catch (e) {
				if (typeof this.addCartProduct.errors.errors.products !== 'undefined') {
					let response = '';
					this.addCartProduct.errors.errors.products.forEach((item) => { response += `${item} \n`; });
					this.alert.toast('error', response, { timer: 4000 });
				} else {
					this.alert.toast('error', this.translate('default_error_message'));
				}
			}
		},
		async removeProduct(product) {
			await this.updateQuantity(0, product, true);
		},
		// Do not call this function directly. Instead, call getCartTotals()
		getTotals() {
			if (this.cartId === null) {
				if (this.country !== null) this.createNewGiftOrderCart();
			} else {
				this.cart.getCart(this.cartId, this.cartOptions).then((response) => {
					response.attributes.products.forEach((item) => {
						this.$set(this.qty, item.item_id, item.qty);
					});
				}).catch((getCartError) => {
					if (![...NOT_FOUND, ...FORBIDDEN, ...BAD_REQUEST].includes(getCartError.status)) {
						this.alert.toast('error', this.translate('default_error_message'));
					}
				});
			}
		},
		getCartRelated() {
			this.cartRelated.getProducts(this.country, 'main');
		},
		createNewGiftOrderCart() {
			return this.createGiftOrderCart.createGiftOrderCart().then(() => {
				this.getCartTotals();
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		renewCart() {
			return this.removeCart.removeCart(this.cartId).then(() => {
				this.getCartTotals();
			}).catch(() => {
				this.alert.toast('error', this.translate('default_error_message'));
			});
		},
		setCartCookie(id, data, getCartTotals = true) {
			if (typeof id !== 'undefined' && (this.cartId !== id || this.cartId === null)) {
				this.$cookie.delete('cart_id');
				this.$cookie.set('cart_id', id, data);
				this.cookieCartId = id;
			}
			if (getCartTotals) {
				return this.getCartTotals();
			}
			return null;
		},
		async usePoints() {
			try {
				await this.usePointsUtil.usePoints(this.cartId);
			} catch (error) {
				this.alert.toast('error', this.translate('default_error_message'));
			} finally {
				this.getCartTotals();
			}
		},
		async removePoints() {
			try {
				await this.removePointsUtil.removePoints(this.cartId);
			} catch (error) {
				this.alert.toast('error', this.translate('default_error_message'));
			} finally {
				this.getCartTotals();
			}
		},
		getUpgradePackagesInfo() {
			return this.upgradePackagesUtil.getAvailablePackages(this.$user.details().id);
		},
		splitPayment(split) {
			this.splitPaymentUtil.splitPayment(this.cartId, { split_payment: split }).then(() => {
				this.getCartTotals();
				this.splitPaymentUtil.data.loading = false;
			});
		},
	},
};
